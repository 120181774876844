import { OptionsItem } from "wini-web-components"
import { Reducers } from "../../module/da"

export enum DataType {
    TAG = 'TAG',
    NUMERIC = "NUMERIC",
    TEXT = "TEXT",
}

export enum ToolType {
    Dashboard = "Dashboard",
    Manager = "Manager",
    Landing = "Landing",
    Report = "Report",
}

export interface TableItem {
    Id: string,
    Name: string,
    DateCreated: number,
    Description: string,
}

export interface WorkflowItem {
    Id: string,
    Name: string,
    DateCreated: number,
    Description: string,
    Sort: number
}

// export enum StageStatus {
//     // INACTIVE = 0,
//     // ACTIVE = 1,
//     // COMPLETED = 2,
//     // CANCELLED = 3,
//     // FAILED = 4,
//     // PENDING = 5,
//     // REJECTED = 6,
//     // SKIPPED = 7,
//     // RUNNING = 8,
//     // STOPPED = 9,
//     // PAUSED = 10,
//     // RESUMED = 11,
//     // EXPIRED = 14,
// }

export interface StageItem {
    Id: string,
    Name: string,
    DateCreated: number,
    Description: string,
    Sort: number,
    WorkflowId: string,
    ParentId?: string,
    Status?: number,
    AssigneeId?: string,
    CollaboratorId?: string,
    Duration?: string,
    FormId?: string
}

export interface SettingStageItem {
    Id: string,
    Name: string,
    DateCreated: number,
    Description: string,
    Value: string,
    Sort: number,
    StageId: string,
}

export interface MenuItem {
    Id: string,
    Name: string,
    TableId?: string,
    PageId?: string,
    To?: string,
    Icon?: string,
    ParentId?: string,
    DateCreated: number,
    Sort: number,
    Description?: string,
    Setting?: string,
}

export interface RelativeItem {
    Id: string,
    TablePK: string,
    TableFK: string,
    Column: string,
    Query?: string,
    DateCreated: number,
    Shortcut?: string | { Title?: string, Sort?: number, IsHidden?: boolean },
    Setting?: string | { Width?: string | number, Title?: string, IsHidden?: boolean, Sort?: number },
    Form?: string | { Label: string, Placeholder?: string, ComponentType?: boolean, Sort?: number, Required?: boolean }
}

export enum FEDataType {
    GID = "GID",
    STRING = "STRING",
    NUMBER = "NUMBER",
    BOOLEAN = "BOOLEAN",
    DATE = "DATE",
    DATETIME = "DATETIME",
    MONEY = "MONEY",
    PASSWORD = "PASSWORD",
    LIST = "LIST",
    HTML = "HTML",
    FILE = "FILE",
}

export enum ValidateType {
    // required = 1,
    email = 2,
    minLength = 3,
    maxLength = 4,
    number = 5,
    phone = 6,
    // date = 7,
    // dateTime = 8,
    // earliestDate = 9,
    // latestDate = 10,
    // earliestTime = 11,
    // latestTime = 12,
    equality = 13,
    greaterThan = 14,
    greaterThanOrEqualTo = 15,
    lessThanOrEqualTo = 16,
    lessThan = 17,
    odd = 18,
    even = 19,
    async = 20
}

export interface ValidateItem {
    type: number,
    /** error message */
    message?: string,
    /** use when validate by compare with specific value, or validate async await with value is url*/
    value?: number | string,
}

export interface ColumnItem {
    Id?: string,
    Name: string,
    TableName?: string,
    DataType: FEDataType,
    DateCreated: number,
    Query?: string,
    Setting?: string | { Width?: string | number, Title?: string, IsHidden?: boolean, Description?: string, Sort?: number },
    Form?: string | { Sort?: number, ComponentType?: ComponentType, Min?: number, Max?: number, DefaultValue?: string | number, Label?: string, Placeholder?: string, AcceptFiles?: string, ReadOnly?: boolean, Disabled?: boolean, Required?: boolean, Validate?: Array<ValidateItem>, Options: Array<OptionsItem> }
}

export class EventStep {
    static FILTER = {
        KEY: 'FILTER',
        Comparision: ["==", "!=", "<", "<=", ">", ">=", "&&", "!", "||"]
    }

    static APPLY = {
        KEY: 'APPLY',
        EXPRESSION: [
            {
                FUNC: "upper(s)",
                TYPE: FEDataType.STRING,
                DESCRIPTION: "Return the uppercase conversion of s. EX: @Field = hello world => upper(@Field) = HELLO WORLD",
                RETURN: FEDataType.STRING
            },
            {
                FUNC: "lower(s)",
                TYPE: FEDataType.STRING,
                DESCRIPTION: "Return the uppercase conversion of s. EX: @Field = HELLO WORLD => lower(@Field) = hello world",
                RETURN: FEDataType.STRING
            },
            {
                FUNC: "startswith(s, txt)",
                TYPE: FEDataType.STRING,
                DESCRIPTION: "Return 1 if txt is the prefix of s, 0 otherwise. EX: @Field = HELLO WORLD => startswith(@Field, 'HE') = 1 ; startswith(@Field, 'he') = 0",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "contains(s, txt)",
                TYPE: FEDataType.STRING,
                DESCRIPTION: "Return the number of occurrences of txt in s, 0 otherwise. If txt is an empty string, return length(s) + 1. EX: @Field = HELLO WORLD => contains(@Field, 'L') = 3 ; contains(@Field, 'l') = 0 ; contains(@Field, '') = 11",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "strlen(s)",
                TYPE: FEDataType.STRING,
                DESCRIPTION: "Return the length of s. EX: @Field = hello world => strlen(@Field) = 11",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "substr(s, offset, count)",
                TYPE: FEDataType.STRING,
                DESCRIPTION: "Return the substring of s, starting at offset and having count characters. If offset is negative, it represents the distance from the end of the string. If count is -1, it means the rest of the string starting at offset. EX: @Field = hello world => substr(@Field, 1, 4) = ell; substr(@Field, 1, -1) = ello world",
                RETURN: FEDataType.STRING
            },
            {
                FUNC: "split(s, [sep=','], [strip=' '])",
                TYPE: FEDataType.STRING,
                DESCRIPTION: "Split a string by any character in the string sep, and strip any characters in strip. If only s is specified, it is split by commas and spaces are stripped. The output is an list. EX: split(@Field,'foo,bar')",
                RETURN: FEDataType.LIST
            },
            {
                FUNC: "timefmt(x, [fmt])",
                TYPE: FEDataType.DATETIME,
                DESCRIPTION: "Return a formatted time string based on a numeric timestamp value x. EX: fmt = '%Y-%m-%d %H:%M:%S', fmt = '%Y-%m-%d',...",
                RETURN: FEDataType.STRING
            },
            {
                FUNC: "parsetime(timesharing, [fmt])",
                TYPE: FEDataType.NUMBER,
                DESCRIPTION: "The opposite of timefmt() - parse a time format using a given format string. EX: fmt = '%Y-%m-%d %H:%M:%S', fmt = '%Y-%m-%d',...",
                RETURN: FEDataType.DATETIME
            },
            {
                FUNC: "day(timestamp)",
                TYPE: FEDataType.DATETIME,
                DESCRIPTION: "Round a Unix timestamp to midnight (00:00) start of the current day.",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "hour(timestamp)",
                TYPE: FEDataType.DATETIME,
                DESCRIPTION: "Round a Unix timestamp to the beginning of the current hour.",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "minute(timestamp)",
                TYPE: FEDataType.DATETIME,
                DESCRIPTION: "Round a Unix timestamp to the beginning of the current minute.",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "month(timestamp)",
                TYPE: FEDataType.DATETIME,
                DESCRIPTION: "Round a Unix timestamp to the beginning of the current month.",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "dayofweek(timestamp)",
                TYPE: FEDataType.DATETIME,
                DESCRIPTION: "Convert a Unix timestamp to the day number (Sunday = 0).",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "dayofmonth(timestamp)",
                TYPE: FEDataType.DATETIME,
                DESCRIPTION: "Convert a Unix timestamp to the day of month number (1 .. 31).",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "dayofyear(timestamp)",
                TYPE: FEDataType.DATETIME,
                DESCRIPTION: "Convert a Unix timestamp to the day of year number (0 .. 365).",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "year(timestamp)",
                TYPE: FEDataType.DATETIME,
                DESCRIPTION: "Convert a Unix timestamp to the current year (e.g. 2018).",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "monthofyear(timestamp)",
                TYPE: FEDataType.DATETIME,
                DESCRIPTION: "Convert a Unix timestamp to the current month (0 .. 11).",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "log(x)",
                TYPE: FEDataType.NUMBER,
                DESCRIPTION: "Return the logarithm of a number, property or subexpression. EX: log(@Field)",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "abs(x)",
                TYPE: FEDataType.NUMBER,
                DESCRIPTION: "Return the absolute number of a numeric expression. EX: abs(@Field1 - @Field2)",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "ceil(x)",
                TYPE: FEDataType.NUMBER,
                DESCRIPTION: "Round to the smallest value not less than x. EX: ceil(@Field1/3.14)",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "floor(x)",
                TYPE: FEDataType.NUMBER,
                DESCRIPTION: "Round to largest value not greater than x. EX: floor(@Field1/3.14)",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "log2(x)",
                TYPE: FEDataType.NUMBER,
                DESCRIPTION: "Return the logarithm of x to base 2. EX: log2(2^@Field)",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "exp(x)",
                TYPE: FEDataType.NUMBER,
                DESCRIPTION: "Return the exponent of x, e.g., e^x. EX: exp(@Field)",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "sqrt(x)",
                TYPE: FEDataType.NUMBER,
                DESCRIPTION: "Return the square root of x. EX: sqrt(@Field)",
                RETURN: FEDataType.NUMBER
            },
            {
                FUNC: "exists(x)",
                DESCRIPTION: "Checks whether a field exists in a document. EX: exists(@Field)",
                RETURN: FEDataType.BOOLEAN
            },
        ]
    }

    static REDUCE = {
        KEY: 'REDUCE',
        EXPRESSION: [Reducers.COUNT, Reducers.COUNT_DISTINCT, Reducers.COUNT_DISTINCTISH, Reducers.SUM, Reducers.MIN, Reducers.MAX, Reducers.AVG, Reducers.STDDEV, Reducers.QUANTILE, Reducers.TOLIST, Reducers.FIRST_VALUE, Reducers.RANDOM_SAMPLE]
    }

    static LIMIT = {
        KEY: 'LIMIT',
    }

    static SORTBY = {
        KEY: 'SORTBY',
    }

    static GROUPBY = {
        KEY: 'GROUPBY',
    }
}

export enum ComponentType {
    text = 'Text',
    img = 'Image',
    icon = 'Icon',
    checkbox = 'Checkbox',
    switch = 'Switch',
    textField = 'Textfield',
    button = 'Button',
    calendar = 'Calendar',
    datePicker = 'DatePicker',
    radio = 'Radio',
    select1 = 'Select1',
    selectMultiple = 'SelectMultiple',
    textArea = 'Textarea',
    table = 'Table',
    dateTimePicker = 'DateTimePicker',
    navLink = 'Navlink',
    rate = 'Rate',
    upload = 'Upload',
    importMultipleFile = 'ImportMultiple',
    ckEditor = 'CkEditor',
    range = 'Range',
    container = "Container",
    chart = "Chart",
    form = "Form",
    card = "Card",
    report = "Report",
    popup = "Popup",
    colorPicker = "ColorPicker",
    none = "none"
}