import { forwardRef, useEffect, useRef, useState } from "react"
import { TableController } from "../../wini/table/controller"
import { DataController } from "../controller"
import { Checkbox, closePopup, ComponentStatus, Dialog, DialogAlignment, Pagination, Popup, showDialog, showPopup, Text, TextField, ToastMessage, Winicon } from "wini-web-components"
import { getTableConfig } from "../config"
import PopupAddEditData from "./settingForm"
import { TableRow } from './view'
import { useForm } from 'react-hook-form'
import { BaseDA, imgFileTypes } from '../../../da/baseDA'
import { FEDataType } from '../../wini/table/da'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const PopupManageTableFK = forwardRef(function PopupManageTableFK({ item, action }, ref) {
    const selectedM = useSelector((store) => store.module.data)
    const _colController = new TableController("column")
    const _relController = new TableController("rel")
    const _dataController = new DataController(action.TbName)
    const methods = useForm({ shouldFocusError: false })
    const [pageDetails, setPageDetails] = useState({ page: 1, size: 10 })
    const [data, setData] = useState({ data: [], totalCount: undefined })
    const [cols, setCols] = useState([])
    const popupRef = useRef()
    const dialogRef = useRef()
    const [selected, setSelected] = useState([])
    const [filterByParentId, setFilter] = useState()
    const { t } = useTranslation()

    const getData = async (page, size) => {
        let bodyJson = {
            page: page ?? pageDetails.page,
            size: size ?? pageDetails.size,
            searchRaw: `@${selectedM.TableId}Id:{*${item.Id}*}`
        }
        if (filterByParentId) {
            bodyJson.key = "ParentId"
            var res = await _dataController.filterByEmptyKey(bodyJson)
        } else {
            res = await _dataController.aggregateList(bodyJson)
        }
        if (res.code === 200) {
            const colFiles = cols.filter(e => e.DataType === FEDataType.FILE).map(e => e.Name)
            if (colFiles.length) {
                const _fileId = methods.getValues("_files") ?? []
                const _tmpFileIds = res.data.map(e => colFiles.map(c => e[c])).flat(Infinity).filter(id => id?.length && _fileId.every(e => e.id !== id))
                BaseDA.getFilesInfor(_tmpFileIds).then(res => {
                    if (res?.length) methods.setValue("_files", [..._fileId, ...res.map(e => { return { ...e, type: e.type ? e.type : imgFileTypes.some(t => e.name.toLowerCase().endsWith(t)) ? "image" : "file" } })])
                })
            }
            _dataController.group({ searchRaw: `@ParentId:{${res.data.map(e => `*${e.Id}*`).join(" | ")}}`, reducers: `GROUPBY 1 @ParentId REDUCE COUNT 0 AS _count` }).then(res => {
                if (res.code === 200) methods.setValue("__countChildren", res.data)
            })
            setData({ data: res.data, totalCount: res.totalCount })
        } else {
            ToastMessage.errors(res.message)
        }
    }

    useEffect(() => {
        if (selectedM.TableId !== action.TbName) {
            _relController.getListSimple({ page: 1, size: 1, query: `@TablePK:{${action.TbName}} @TableFK:{${action.TbName}}`, returns: ["Id"] }).then(res => {
                if (res.code === 200) setFilter(res.data.length)
            })
        } else setFilter(0)
    }, [])

    useEffect(() => {
        if (typeof filterByParentId === "number") {
            _colController.getListSimple({ page: 1, size: 100, query: `@TableName:{${action.TbName}} -@Name:{Id}`, returns: ["Id", "Name", "Setting", "DataType", "Form"] }).then(res => {
                if (res.code === 200) {
                    const _colRes = res.data.map((e) => {
                        e.Form = e.Form ? JSON.parse(e.Form) : {}
                        return e
                    }).sort((a, b) => a.Name === "Name" ? -1 : b.Name === "Name" ? 1 : a.Name.Sort - b.Name.Sort)
                    setCols(_colRes)
                }
            })
        }
    }, [filterByParentId])

    useEffect(() => {
        if (cols.length) getData()
    }, [cols])

    const showPopupAddEdit = (e) => {
        showPopup({
            ref: popupRef,
            style: { width: '80rem' },
            hideButtonClose: true,
            body: <PopupAddEditData
                ref={popupRef}
                id={e?.Id?.toLowerCase()?.replaceAll("-", "")}
                action={{ ...action, value: item.Id }}
                module={action.TbName}
                onSuccess={getData}
            />
        })
    }

    return <div className='col' style={{ flex: 1, height: '100%', width: '100%' }}>
        <Popup ref={popupRef} />
        <Dialog ref={dialogRef} />
        <div className='row' style={{ padding: '1.2rem 1.2rem 1.2rem 2.4rem', alignItems: 'start', gap: '0.8rem', borderBottom: 'var(--neutral-bolder-border)' }}>
            <Text className='heading-7' style={{ flex: 1, marginTop: '0.4rem' }} maxLine={2}>{action.TbName} of {item.Name}</Text>
            <button type='button' className='row icon-button32' onClick={() => { closePopup(ref) }}>
                <Winicon src={"fill/user interface/e-remove"} size={"2.4rem"} />
            </button>
        </div>
        <div className='col' style={{ padding: '0 2.4rem 1.6rem', width: '100%', flex: 1, height: '100%' }}>
            <div className='row' style={{ padding: '1.2rem 0', gap: "0.8rem" }}>
                <button type='button' className='row' style={{ padding: '0 1.2rem', border: "var(--neutral-bolder-border)", gap: '0.8rem', height: '3.2rem', borderRadius: '0.8rem' }} onClick={() => { showPopupAddEdit() }}>
                    <Winicon src={"outline/user interface/e-add"} size={"1.4rem"} />
                    <Text className='button-text-3' style={{ color: "var(--neutral-text-subtitle-color)" }}>{t("add")} {action.TbName} to {selectedM.TableId}</Text>
                </button>
                <div style={{ flex: 1 }} />
                <TextField
                    placeholder='Search'
                    prefix={<Winicon src={"fill/development/zoom"} size={"1.4rem"} />}
                    suffix={<button type='button' onClick={(event) => { }}>
                        <Winicon src={"fill/arrows/down-arrow"} size={"1.2rem"} />
                    </button>}
                    name='txtSearch'
                    // onComplete={searchKeyAction}
                    // defaultValue={`${searchParams.get(searchkeyVl) ?? ''}`}
                    register={methods.register('search-key-value')}
                    style={{ padding: '0 1.2rem', borderRadius: '0.8rem', height: '3.2rem', width: '26.8rem' }}
                />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { }}>
                    <Winicon src='outline/user interface/setup-preferences' size={'1.6rem'} />
                    <Text className='button-text-3'>Filter</Text>
                </button>
                <div className='row divider' style={{ height: '1.2rem', margin: 0 }} />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { }}>
                    <Winicon src='fill/arrows/enlarge' size={'1.6rem'} />
                    <Text className='button-text-3'>Sort</Text>
                </button>
            </div>
            {cols.length ? <div className="col table">
                <div className="row header">
                    <div className='row cell' style={{ order: 0 }}>
                        <Checkbox size={'1.6rem'} value={selected.length ? (data.data.every(e => selected.includes(e.Id)) ? true : undefined) : false} onChange={(v) => {
                            const _filter = selected.filter(id => data.data.every(e => id !== e.Id))
                            if (v) setSelected([..._filter, ...data.data.map(e => e.Id)])
                            else setSelected(_filter)
                        }} />
                    </div>
                    {cols.map((_col, i) => {
                        const { _minW } = getTableConfig(_col)
                        return <div key={_col.Id} id={_col.Id} style={{ flex: _col.Width ? undefined : 1, minWidth: i > 0 ? _minW : "36rem", width: _col.Width, order: (selectedM.Setting?.column?.[_col.Name] ?? i) + 1 }} className='row header-cell' >
                            <Text className='heading-9' maxLine={1} style={{ flex: 1 }}>{_col.Name === "DateCreated" ? t("DateCreated") : (_col.Form.Label ?? _col.Name)}</Text>
                            <button type='button' className="row" onClick={(ev) => { }}><Winicon src='fill/arrows/down-arrow' size={'0.8rem'} /></button>
                        </div>
                    })}
                    <div ref={actionRef => {
                        if (actionRef && !cols[0].Width) {
                            setCols(cols.map(e => ({ ...e, Width: actionRef.parentElement.querySelector(`div[id="${e.Id}"]`)?.offsetWidth })))
                        }
                    }} className='row header-cell' style={{ order: 1000, width: "10.8rem" }}>
                        <Text className='heading-9' style={{ flex: 1, textOverflow: "ellipsis", textAlign: "center" }} maxLine={1}>{t("action")}</Text>
                    </div>
                </div>
                {
                    data.data.map((item, index) => <TableRow
                        key={`${item.Id}-${index}`}
                        index={index + pageDetails.size * (pageDetails.page - 1) + 1}
                        item={item}
                        cols={cols}
                        methods={methods}
                        selected={selected}
                        dataController={_dataController}
                        setSelected={setSelected}
                        showPopupAddEdit={showPopupAddEdit}
                        onDelete={() => {
                            showDialog({
                                ref: dialogRef,
                                alignment: DialogAlignment.center,
                                status: ComponentStatus.WARNING,
                                title: "Confirm delete",
                                content: `Are you sure to remove this ${action.TbName} out of ${item.Name}?`,
                                submitTitle: "Delete",
                                onSubmit: async () => {
                                    let _editItem = { ...item }
                                    _editItem[`${selectedM.TableId}Id`] = _editItem[`${selectedM.TableId}Id`].split(",").filter(id => id !== item.Id).join(",")
                                    const res = await _dataController.edit([_editItem])
                                    if (res.code !== 200) return ToastMessage.errors(res.message)
                                    getData()
                                }
                            })
                        }}
                    />)
                }
            </div> : null}
            <div className='row' style={{ height: '5.6rem', borderTop: "var(--neutral-bolder-border)" }}>
                <Pagination
                    currentPage={pageDetails.page}
                    itemPerPage={pageDetails.size}
                    totalItem={data.totalCount ?? 0}
                    onChangePage={(page, size) => {
                        if (pageDetails.page !== page || pageDetails.size !== size) {
                            setPageDetails({ page: page, size: size });
                            getData(page, size)
                        }
                    }}
                />
            </div>
        </div>
    </div>
})

export default PopupManageTableFK