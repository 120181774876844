import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { FormItem, FormType } from "../da";
import { useForm } from "react-hook-form";
import { TableController } from "../../wini/table/controller";
import { DataController } from "../controller";
import { ComponentType, FEDataType, RelativeItem } from "../../wini/table/da";
import { randomGID, Ultis } from "../../../Utils";
import { Select1Form, SelectMultipleForm } from "../../../project-component/component-form";
import { hashPassword, regexGetVariableByThis, RenderComponentByType, validateForm } from "../config";
import { BaseDA } from "../../../da/baseDA";
import { ToastMessage } from "wini-web-components";

export default function RenderFormByType(props: { id?: string, formItem: FormItem, className?: string, style?: CSSProperties, isActive?: boolean, data?: { [p: string]: any }, columns?: Array<any> }) {
    const methodOptions = useForm({ shouldFocusError: false })
    const methods = useForm<any>({ shouldFocusError: false, defaultValues: { Id: randomGID() } })
    const [cols, setCols] = useState<Array<any>>([])
    const [rels, setRels] = useState<Array<any>>([])
    const [watchRel, setWatchRel] = useState<Array<any>>([])
    const [staticRel, setStaticRel] = useState<Array<any>>([])
    const _colController = new TableController("column")
    const _relController = new TableController("rel")
    const successBtnRef = useRef<any>()

    const _onSubmit = async (ev: { [k: string]: any }) => {
        let dataItem = { ...ev }
        dataItem.DateCreated ??= Date.now()
        let validateDataForm: { [k: string]: any } = {}
        Object.keys(dataItem).forEach((key) => {
            if (typeof dataItem[key] === "string") validateDataForm[key] = dataItem[key].trim()
        })
        const _val = await validateForm({
            list: cols.filter(e => e.Form.Validate?.length).map(e => {
                return {
                    Name: e.Name,
                    Validate: e.Form.Validate
                }
            }) as any,
            formdata: validateDataForm
        })
        // Cập nhật lỗi vào React Hook Form
        if (_val && Object.keys(_val).length > 0) {
            Object.keys(_val).forEach((field: any) => {
                methods.setError(field, { message: _val[field].join(', ') });
            });
            return;
        }
        // Nếu có lỗi, dừng lại không thực hiện submit
        for (let _col of cols) {
            if (_col.Name === "DateCreated") {
                dataItem[_col.Name] ??= Date.now()
            } else if (dataItem[_col.Name] != undefined) {
                if (!_col.Query) {
                    switch (_col.DataType) {
                        case FEDataType.GID:
                            break;
                        case FEDataType.STRING:
                            if (Array.isArray(dataItem[_col.Name])) {
                                dataItem[_col.Name] = dataItem[_col.Name].join(",")
                            } else if (typeof dataItem[_col.Name] !== 'string') {
                                dataItem[_col.Name] = `${dataItem[_col.Name]}`
                            }
                            break;
                        case FEDataType.BOOLEAN:
                            dataItem[_col.Name] = [true, 1, "true"].includes(dataItem[_col.Name]) ? true : false
                            break;
                        case FEDataType.NUMBER:
                            dataItem[_col.Name] = typeof dataItem[_col.Name] === 'string' ? parseFloat(dataItem[_col.Name]) : dataItem[_col.Name]
                            break;
                        case FEDataType.DATE:
                            dataItem[_col.Name] = Ultis.stringToDate(dataItem[_col.Name]).getTime()
                            break;
                        case FEDataType.DATETIME:
                            dataItem[_col.Name] = Ultis.stringToDate(dataItem[_col.Name], 'dd/mm/yyyy hh:mm:ss').getTime()
                            break;
                        case FEDataType.MONEY:
                            dataItem[_col.Name] = parseInt(dataItem[_col.Name].replaceAll(',', ''))
                            break;
                        case FEDataType.PASSWORD:
                            dataItem[_col.Name] = await hashPassword(dataItem[_col.Name])
                            break;
                        case FEDataType.FILE:
                            if (ev[_col.Name]) {
                                if (ev[_col.Name] instanceof File) {
                                    const res = await BaseDA.uploadFiles([ev[_col.Name]])
                                    if (res[0]) dataItem[_col.Name] = res[0].id
                                } else {
                                    dataItem[_col.Name] = ev[_col.Name].id
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        }
        for (let _rel of rels) {
            if (dataItem[_rel.Column] && Array.isArray(dataItem[_rel.Column]))
                dataItem[_rel.Column] = dataItem[_rel.Column].join(",")
        }
        const dataController = new DataController(props.formItem.TbName)
        const res = await dataController.add([dataItem])
        if (res.code !== 200) return ToastMessage.errors(res.message)
        ToastMessage.success(`Add ${props.formItem.TbName} successfully!`)
        if (successBtnRef.current) successBtnRef.current.click()
    }

    const _onError = (ev: any) => { }

    useEffect(() => {
        if (props.formItem) {
            if (props.columns) {
                const _cols = props.columns.filter((e: any) => e.type === "col" && (props.formItem.Props as any)[e.Name] >= 0).map((e: any) => {
                    let _tmp = { ...e, Form: e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : { ...e.Form } : { Required: true } }
                    _tmp.Form.Sort = (props.formItem.Props as any)[e.Name]
                    return _tmp
                })
                const _rels = props.columns.filter((e: any) => e.type === "rel" && (props.formItem.Props as any)[e.Column] >= 0).map((e: any) => {
                    let _tmp = { ...e, Form: e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : { ...e.Form } : { Required: true } }
                    _tmp.Form.Sort = (props.formItem.Props as any)[e.Column]
                    return _tmp
                })
                setCols(_cols)
                setRels(_rels)
            } else {
                _relController.getListSimple({ page: 1, size: 100, query: `@TableFK:{${props.formItem.TbName}} @Column:{${Object.keys(props.formItem.Props).filter(p => (props.formItem.Props as any)[p] >= 0).join(" | ")}}` }).then(res => {
                    if (res.code === 200) setRels(res.data.map((e: any) => {
                        let _tmp = { ...e, Form: e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : { ...e.Form } : { Required: true } }
                        _tmp.Form.Sort = (props.formItem.Props as any)[e.Name]
                        return _tmp
                    }))
                })
                _colController.getListSimple({ page: 1, size: 100, query: `@TableName:{${props.formItem.TbName}} @Name:{${Object.keys(props.formItem.Props).filter(p => (props.formItem.Props as any)[p] >= 0).join(" | ")}}` }).then(res => {
                    if (res.code === 200) {
                        setCols(res.data.map((e: any) => {
                            let _tmp = { ...e, Form: e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : { ...e.Form } : { Required: true } }
                            _tmp.Form.Sort = (props.formItem.Props as any)[e.Column]
                            return _tmp
                        }))
                    }
                })
            }
        }
    }, [props.formItem, props.columns])

    useEffect(() => {
        if (rels.length && props.isActive) {
            setStaticRel(rels.filter(e => !e.Query || !e.Query.match(regexGetVariableByThis)))
            setWatchRel(rels.filter(e => e.Query && e.Query.match(regexGetVariableByThis)?.length))
        }
    }, [rels.length, props.isActive])

    useEffect(() => {
        if (watchRel.length) getOptions({
            relatives: watchRel.filter(e => e.Query.match(regexGetVariableByThis).some((m: string) => methods.watch((regexGetVariableByThis.exec(m) ?? [])[1]))),
            isWatch: true
        })
    }, [methods.watch()])

    useEffect(() => {
        if (staticRel.length) getOptions({ relatives: staticRel })
    }, [staticRel])

    const getOptions = ({ relatives = [], isWatch = false }: { relatives?: RelativeItem[], page?: number, isWatch?: boolean }) => {
        relatives.forEach((_rel) => {
            const _dataPKController = new DataController(_rel.TablePK)
            if (_rel.TablePK === props.formItem.TbName) {
                _dataPKController.filterByEmptyKey({
                    page: 1, size: 500, searchRaw: _rel.Query?.length ? isWatch ? _rel.Query.replace(regexGetVariableByThis, (m) => methods.getValues((regexGetVariableByThis.exec(m) ?? [])[1])) : _rel.Query : "*", key: `ParentId`
                }).then(async (res) => {
                    if (res.code === 200) methodOptions.setValue(`${_rel.Column}_Options`, res.data ?? [])
                })
            } else {
                _dataPKController.getListSimple({
                    page: 1, size: 1000, query: isWatch ? _rel.Query?.replace(regexGetVariableByThis, (m) => methods.getValues((regexGetVariableByThis.exec(m) ?? [])[1])) : _rel.Query, returns: ["Id", "Name", "ParentId"]
                }).then((res) => {
                    if (res.code === 200) methodOptions.setValue(`${_rel.Column}_Options`, res.data ?? [])
                })
            }
        })
    }

    useEffect(() => {
        if (props.data && props.isActive) {
            const dataItem = props.data
            Object.keys(props.data).forEach((prop: any) => {
                const _col = cols.find(e => e.Name === prop)
                const _rel = rels.find(e => e.Column === prop)
                if (_col) {
                    switch (_col.DataType) {
                        case FEDataType.GID:
                            methods.setValue(prop, dataItem[prop])
                            break;
                        case FEDataType.STRING:
                            methods.setValue(prop, dataItem[prop])
                            break;
                        case FEDataType.BOOLEAN:
                            methods.setValue(prop, dataItem[prop])
                            break;
                        case FEDataType.NUMBER:
                            methods.setValue(prop, typeof dataItem[prop] === 'string' ? parseFloat(dataItem[prop]) : dataItem[prop])
                            break;
                        case FEDataType.DATE:
                            methods.setValue(prop, Ultis.datetoString(new Date(typeof dataItem[prop] === 'string' ? parseInt(dataItem[prop]) : dataItem[prop])))
                            break;
                        case FEDataType.DATETIME:
                            methods.setValue(prop, Ultis.datetoString(new Date(typeof dataItem[prop] === 'string' ? parseInt(dataItem[prop]) : dataItem[prop]), 'dd/mm/yyyy hh:mm:ss'))
                            break;
                        case FEDataType.MONEY:
                            methods.setValue(prop, Ultis.money(dataItem[prop]))
                            break;
                        case FEDataType.PASSWORD:
                            methods.setValue(prop, dataItem[prop])
                            break;
                        default:
                            break;
                    }
                } else if (_rel) {
                    const _tmpParse = dataItem[prop]?.length ? dataItem[prop].split(",") : []
                    methods.setValue(prop, _rel.ComponentType === ComponentType.selectMultiple ? _tmpParse : _tmpParse[0])
                } else {
                    methods.setValue(prop, dataItem[prop])
                }
            })
        } else {
            cols.filter((e) => e.DefaultValue != undefined).forEach((_col) => {
                switch (_col.DataType) {
                    case FEDataType.GID:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    case FEDataType.STRING:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    case FEDataType.BOOLEAN:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    case FEDataType.NUMBER:
                        methods.setValue(_col.Name, typeof _col.DefaultValue === 'string' ? parseFloat(_col.DefaultValue) : _col.DefaultValue)
                        break;
                    case FEDataType.DATE:
                        methods.setValue(_col.Name, Ultis.datetoString(new Date(typeof _col.DefaultValue === 'string' ? parseInt(_col.DefaultValue) : _col.DefaultValue)))
                        break;
                    case FEDataType.DATETIME:
                        methods.setValue(_col.Name, Ultis.datetoString(new Date(typeof _col.DefaultValue === 'string' ? parseInt(_col.DefaultValue) : _col.DefaultValue), 'dd/mm/yyyy hh:mm:ss'))
                        break;
                    case FEDataType.MONEY:
                        methods.setValue(_col.Name, Ultis.money(_col.DefaultValue))
                        break;
                    case FEDataType.PASSWORD:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    default:
                        break;
                }
            })
        }
    }, [props.data])

    return <form id={props.id} className={`col ${props.className ?? ""}`} style={props.style}>
        <button hidden type="submit" className="submit-form" onClick={methods.handleSubmit(_onSubmit)} />
        <button ref={successBtnRef} hidden type="button" className="success-form" />
        {cols.filter(e => e.Name !== "Id" && e.Name !== "DateCreated").map((_col: any) => {
            return <RenderComponentByType
                key={_col.Id}
                methods={methods}
                fieldItem={_col}
                label={undefined}
                style={{ order: _col.Form.Sort }}
                className={props.formItem.Type === FormType.leftLabelCol ? "row" : undefined}
                labelStyle={props.formItem.Type === FormType.leftLabelCol ? { width: "16rem" } : undefined}
            />
        })}
        {rels.map((_rel: any) => {
            const _options = methodOptions.watch(`${_rel.Column}_Options`) ?? []
            let _mapOptions = _options.map((e: any) => { return { id: e.Id, name: e.Name, parentId: e.ParentId } })
            switch (_rel.Form?.ComponentType) {
                case ComponentType.selectMultiple:
                    return <SelectMultipleForm
                        key={_rel.Id}
                        required={_rel.Form.Required}
                        control={methods.control as any}
                        errors={methods.formState.errors}
                        name={_rel.Column}
                        label={_rel.Form.Label ?? _rel.Column}
                        placeholder={_rel.Form.Placeholder}
                        style={{ order: _rel.Form.Sort }}
                        className={props.formItem.Type === FormType.leftLabelCol ? "row" : undefined}
                        labelStyle={props.formItem.Type === FormType.leftLabelCol ? { width: "16rem" } : undefined}
                        options={_mapOptions}
                    />
                default:
                    return <Select1Form
                        key={_rel.Id}
                        required={_rel.Form.Required}
                        control={methods.control as any}
                        errors={methods.formState.errors}
                        name={_rel.Column}
                        label={_rel.Form.Label ?? _rel.Column}
                        placeholder={_rel.Form.Placeholder}
                        style={{ order: _rel.Form.Sort }}
                        className={props.formItem.Type === FormType.leftLabelCol ? "row" : undefined}
                        labelStyle={props.formItem.Type === FormType.leftLabelCol ? { width: "16rem" } : undefined}
                        options={_mapOptions}
                    />
            }
        })}
    </form>
}
