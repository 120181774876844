import styles from './view.module.css'
import { forwardRef, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { closePopup, Switch, Text, TextField, Winicon } from "wini-web-components"
import { DataController } from "../controller"
import { TableController } from '../../wini/table/controller'
import { useDispatch, useSelector } from 'react-redux'
import { ModuleActions } from '../reducer'

const DrawerSettingTableManager = forwardRef(function DrawerSettingTableManager(data, ref) {
    const selectedM = useSelector((store) => store.module.data)
    const menuController = new TableController("menu")
    const [cols, setCols] = useState([])
    const [rels, setRels] = useState([])
    const [actions, setActions] = useState([])
    const [toggleCol, setToggleCol] = useState(true)
    const [toggleAction, setToggleAction] = useState(true)
    const [selectedId, setSelectedId] = useState(data.cols.find(e => e.Name === "Name").Id)
    const dispatch = useDispatch()

    const onMouseDown = (ev) => {
        ev.preventDefault()
        const _colTile = ev.target.closest(`.row[class*="setting-column-tile"]`)
        const _tmp = [..._colTile.parentElement.children].sort((a, b) => parseInt(window.getComputedStyle(a).order ?? 0) - parseInt(window.getComputedStyle(b).order ?? 0))
        function onDrag(event) {
            if (_colTile) {
                _colTile.style.display = "none"
                let _demo = document.body.querySelector(`.demo-sort`)
                if (!_demo) {
                    _demo = document.createElement("div")
                    _demo.className = 'demo-sort'
                }
                const _children = _tmp.filter(e => e.id !== _colTile.id && !e.classList.contains('demo-sort'))
                let _order = 0
                let _distance = 0
                let closestHTML = [..._children].sort((aHTML, bHTML) => {
                    let aRect = aHTML.getBoundingClientRect()
                    let bRect = bHTML.getBoundingClientRect()
                    let a_center_oy = Math.abs(event.pageY - (aRect.y + aRect.height / 2))
                    let b_center_oy = Math.abs(event.pageY - (bRect.y + bRect.height / 2))
                    return a_center_oy - b_center_oy
                })[0]
                if (closestHTML) {
                    let htmlRect = closestHTML.getBoundingClientRect()
                    _order = parseInt(window.getComputedStyle(closestHTML).order)
                    _distance = event.pageY - (htmlRect.y + htmlRect.height / 2)
                    if (_distance < 0 && closestHTML.id !== _children[0].id) _order--
                } else _order = _children.length - 1
                _demo.style.order = _order
                _colTile.style.order = _order
                if (_demo.parentElement !== _colTile.parentElement) _colTile.parentElement.appendChild(_demo)
            }
        }
        document.body.addEventListener('mousemove', onDrag)
        document.body.onmouseup = () => {
            if (_colTile) {
                document.body.querySelector(`.demo-sort`)?.remove()
                _colTile.style.removeProperty("display")
                const _childrenId = [..._colTile.parentElement.children].sort((a, b) => parseInt(window.getComputedStyle(a).order ?? 0) - parseInt(window.getComputedStyle(b).order ?? 0)).map(e => e.id)
                let listProps = []
                setCols(cols.map(e => {
                    let _index = _childrenId.findIndex(id => e.Id === id)
                    listProps.push({ key: e.Name, index: _index })
                    return { ...e, Form: { ...e.Form, Sort: _index } }
                }))
                setRels(rels.map(e => {
                    let _index = _childrenId.findIndex(id => e.Id === id)
                    listProps.push({ key: e.Column, index: _index })
                    return { ...e, Form: { ...e.Form, Sort: _index } }
                }))
                listProps = listProps.sort((a, b) => a.index - b.index)
                let _oldProps = selectedM.Setting?.column ?? {}
                let _newProps = {}
                listProps.forEach(e => {
                    _newProps[e.key] = _oldProps[e.key] === -1 ? -1 : e.index
                })
                const newSetting = { ...(selectedM.Setting ?? {}), column: _newProps }
                menuController.edit([{ ...selectedM, Setting: JSON.stringify(newSetting) }]).then(res => {
                    if (res.code === 200) ModuleActions.setSelectedM(dispatch, { ...selectedM, Setting: newSetting })
                })
            }
            document.body.removeEventListener('mousemove', onDrag)
            document.body.onmouseup = undefined
        }
    }

    useEffect(() => {
        let menuColumn = selectedM.Setting?.column ?? {}
        const _listKey = Object.keys(menuColumn).map(k => k)
        setCols(data.cols.map((e, i) => {
            let tmp = { ...e }
            tmp.Form.Sort = _listKey.indexOf(tmp.Name) >= 0 ? _listKey.indexOf(tmp.Name) : i
            return tmp
        }))
        setRels(data.rels.map((e, i) => {
            let tmp = { ...e }
            tmp.Form.Sort = _listKey.indexOf(tmp.Column) >= 0 ? _listKey.indexOf(tmp.Column) : (i + data.cols.length)
            return tmp
        }))
        const menuAction = selectedM.Setting?.action ?? []
        setActions(data.actions.map((e, i) => {
            const tmp = menuAction.find(a => a.TbName === e.TableFK)
            return { TbName: e.TableFK, Title: tmp?.Title ?? e.TableFK, Sort: tmp?.Sort ?? i, Hidden: tmp?.Hidden ?? true }
        }))
    }, [])

    return <div className='col' style={{ width: '32rem', backgroundColor: 'var(--neutral-absolute-background-color)', flex: 1 }}>
        <div className='row popup-header' style={{ padding: '1.2rem 1.2rem 1.2rem 2.4rem' }}>
            <Text className='heading-7' style={{ flex: 1 }}>Setting</Text>
            <Winicon src={"fill/user interface/e-remove"} size={"2rem"} style={{ padding: "0.4rem" }} onClick={() => { closePopup(ref) }} />
        </div>
        <div className='col' style={{ flex: 1, overflow: "hidden auto", padding: '0.8rem 0', gap: '0.4rem' }}>
            <div className='row' style={{ gap: '1.2rem', padding: '0.4rem 1.6rem' }}>
                <button type='button' className='row icon-button20' onClick={() => { setToggleCol(!toggleCol) }}>
                    <Winicon src={`fill/arrows/triangle-${toggleCol ? "down" : "right"}`} size={"1.4rem"} />
                </button>
                <Text className='heading-8' style={{ flex: 1 }}>Column</Text>
            </div>
            <div className='col' style={{ display: toggleCol ? 'flex' : 'none' }}>
                {cols.map((item) => {
                    return <div key={item.Id} id={item.Id} className={`row ${styles['setting-column-tile']} ${item.Id === selectedId ? styles['selected'] : ""}`} style={{ order: item.Form.Sort }} onClick={() => { setSelectedId(item.Id) }}>
                        <div onMouseDown={onMouseDown} className='row' style={item.Name === "Name" ? { pointerEvents: 'none', padding: "0.2rem" } : { padding: "0.2rem" }}>
                            <Winicon src={"fill/layout/grip-dots-vertical"} size={"2rem"} />
                        </div>
                        <Text className='body-3' style={{ flex: 1, height: "3.2rem", padding: "0.4rem" }}>{item.Form.Label ?? item.Name}</Text>
                        <Switch disabled={item.Name === "Name"} value={selectedM.Setting?.column?.[item.Name] !== -1} onChange={async (v) => {
                            let tmp = { ...(selectedM.Setting?.column ?? { Name: 0 }) }
                            tmp[item.Name] = v ? item.Form.Sort : -1
                            const newSetting = { ...selectedM, Setting: { ...(selectedM.Setting ?? {}), column: tmp } }
                            const res = await menuController.edit([{ ...selectedM, Setting: JSON.stringify(newSetting.Setting) }])
                            if (res.code === 200) ModuleActions.setSelectedM(dispatch, newSetting)
                        }} />
                    </div>
                })}
                {rels.map((item) => {
                    return <div key={item.Id} id={item.Id} className={`row ${styles['setting-column-tile']} ${item.Id === selectedId ? styles['selected'] : ""}`} style={{ order: item.Form.Sort }} onClick={() => { setSelectedId(item.Id) }}>
                        <div onMouseDown={onMouseDown} className='row' style={{ padding: "0.2rem" }}>
                            <Winicon src={"fill/layout/grip-dots-vertical"} size={"2rem"} />
                        </div>
                        <Text className='body-3' style={{ flex: 1, height: "3.2rem", padding: "0.4rem" }}>{item.Form.Label ?? item.Column}</Text>
                        <Switch value={selectedM.Setting?.column?.[item.Column] !== -1} onChange={async (v) => {
                            let tmp = { ...(selectedM.Setting?.column ?? { Name: 0 }) }
                            tmp[item.Column] = v ? item.Form.Sort : -1
                            const newSetting = { ...selectedM, Setting: { ...(selectedM.Setting ?? {}), column: tmp } }
                            const res = await menuController.edit([{ ...selectedM, Setting: JSON.stringify(newSetting.Setting) }])
                            if (res.code === 200) ModuleActions.setSelectedM(dispatch, newSetting)
                        }} />
                    </div>
                })}
            </div>
            <div className='row' style={{ gap: '1.2rem', padding: '0.4rem 1.6rem' }}>
                <button type='button' className='row icon-button20' onClick={() => { setToggleAction(!toggleAction) }}>
                    <Winicon src={`fill/arrows/triangle-${toggleAction ? "down" : "right"}`} size={"1.4rem"} />
                </button>
                <Text className='heading-8' style={{ flex: 1 }}>Action</Text>
            </div>
            <div className='col' style={{ display: toggleAction ? 'flex' : 'none' }}>
                {actions.map((item) => {
                    return <div key={item.TbName} className={`row ${styles['setting-column-tile']}`} style={{ order: item.Sort }}>
                        <button type='button' className='row' style={{ padding: "0.2rem" }}>
                            <Winicon src={"fill/layout/grip-dots-vertical"} size={"2rem"} />
                        </button>
                        <TextField
                            className='placeholder-2'
                            defaultValue={item.Title}
                            onComplete={(ev) => ev.target.blur()}
                            onFocus={(ev) => { ev.target.select() }}
                            onBlur={async (ev) => {
                                const _newTitle = ev.target.value.trim()
                                let tmp = {
                                    column: selectedM.Setting?.column ?? { Name: 0 },
                                    action: actions.map(e => {
                                        if (e.TbName === item.TbName) return { ...e, Title: _newTitle }
                                        return e
                                    })
                                }
                                const res = await menuController.edit([{ ...selectedM, Setting: JSON.stringify(tmp) }])
                                if (res.code === 200) ModuleActions.setSelectedM(dispatch, { ...selectedM, Setting: tmp })
                            }}
                        />
                        <Switch value={!item.Hidden} onChange={async (v) => {
                            let tmp = {
                                column: selectedM.Setting?.column ?? { Name: 0 },
                                action: actions.map(e => {
                                    if (e.TbName === item.TbName) return { ...e, Hidden: !v }
                                    return e
                                })
                            }
                            const res = await menuController.edit([{ ...selectedM, Setting: JSON.stringify(tmp) }])
                            if (res.code === 200) ModuleActions.setSelectedM(dispatch, { ...selectedM, Setting: tmp })
                        }} />
                    </div>
                })}
            </div>
        </div>
    </div>
})

const PopupActions = forwardRef(function PopupActions({ item, onEdit = () => { }, onDelete = () => { }, onSelectAction = () => { } }, ref) {
    const selectedM = useSelector((store) => store.module.data)
    const methods = useForm({ shouldFocusError: false })

    useEffect(() => {
        if (selectedM.Setting?.action?.length) {
            selectedM.Setting.action.forEach((_rel) => {
                const _relDataCotroller = new DataController(_rel.TbName)
                _relDataCotroller.getListSimple({
                    page: 1,
                    size: 1,
                    query: `@${selectedM.TableId}Id:{${item.Id}*}`,
                    returns: ["Id"]
                }).then((res) => {
                    if (res.code === 200) methods.setValue(_rel.TbName, res.totalCount)
                })
            })
        }
    }, [selectedM.Setting?.action])

    return <div className='col popup-actions' style={{ borderRadius: '0.8rem', minWidth: '16rem' }}>
        <button type='button' className='row' onClick={() => {
            closePopup(ref)
            onEdit()
        }}>
            <Winicon src={"fill/user interface/edit"} size={"1.6rem"} />
            <Text className='button-text-3'>Edit</Text>
        </button>
        {(selectedM.Setting?.action ?? []).filter(e => e.Hidden !== true).map(e => {
            return <button key={e.TbName} type='button' className='row' onClick={() => { onSelectAction(e) }}>
                <div style={{ width: '1.6rem' }} />
                <Text className='button-text-3'>{e.Title ?? e.TableFK}({methods.watch(e.TbName)})</Text>
            </button>
        })}
        <button type='button' className='row' onClick={() => {
            closePopup(ref)
            onDelete()
        }}>
            <Winicon src={"fill/user interface/trash-can"} size={"1.6rem"} />
            <Text className='button-text-3'>Delete</Text>
        </button>
    </div>
})

export { DrawerSettingTableManager, PopupActions }